import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import './styles/main.css';

// import all of the images we need
// import image_1 from "./styles/assets/recipes/recipe-1.jpeg";
// import image_2 from "./styles/assets/recipes/recipe-2.jpeg";
// import image_3 from "./styles/assets/recipes/recipe-3.jpeg";
// import image_4 from "./styles/assets/recipes/recipe-4.jpeg";

import ReactDOM from "react-dom";
import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

// export default function Home() {
//   return (
//           <html lang="en">
//             <body>
//               {/* <!-- end of nav --> */}
//               {/* <!-- main --> */}
//               <main className="page">
//                 {/* <!-- header --> */}
//                 <header className="hero">
//                   <div className="hero-container">
//                     <div className="hero-text">
//                       <h1_white>goodfoodwithmoo</h1_white>
//                       <h4>All of your favorite recipes from Instagram!</h4>
//                     </div>
//                   </div>
//                 </header>
//                 {/* <!-- end of header --> */}
//                 {/* <!-- recipes container --> */}
//                 <section className="recipes-container">
//                   {/* <!-- tag container --> */}
//                   <div className="tags-container">
//                     <h4>recipes</h4>
//                     <div className="tags-list">
//                       <a href="tag-template.html">Beef (1)</a>
//                       <a href="tag-template.html">Breakfast (2)</a>
//                       <a href="tag-template.html">Carrots (3)</a>
//                       <a href="tag-template.html">Food (4)</a>
//                     </div>
//                   </div>
//                   {/* <!-- end of tag container --> */}
                  
//                   {/* <!-- recipes list --> */}
//                   <div className="recipes-list">
//                     {/* <!-- single recipe --> */}
//                     <a href="single-recipe.html" className="recipe">
//                       <img src={image_1} alt="food" className="img recipe-img"/>
//                       <h5>carne asada</h5>
//                       <p>Prep : 15min | Cook : 5min</p>
//                     </a>
//                     {/* <!-- end of single recipe --> */}
//                     {/* <!-- single recipe --> */}
//                     <a href="single-recipe.html" className="recipe">
//                       <img src={image_2} alt="food" className="img recipe-img"/>
//                       <h5>greek ribs</h5>
//                       <p>Prep : 15min | Cook : 5min</p>
//                     </a>
//                     {/* <!-- end of single recipe --> */}
//                     {/* <!-- single recipe --> */}
//                     <a href="single-recipe.html" className="recipe">
//                       <img src={image_3} alt="food" className="img recipe-img"/>
//                       <h5>vegetable soup</h5>
//                       <p>Prep : 15min | Cook : 5min</p>
//                     </a>
//                     {/* <!-- end of single recipe --> */}
//                     {/* <!-- single recipe --> */}
//                     <a href="single-recipe.html" className="recipe">
//                       <img src={image_4} alt="food" className="img recipe-img"/>
//                       <h5>banana pancakes</h5>
//                       <p>Prep : 15min | Cook : 5min</p>
//                     </a>
//                     {/* <!-- end of single recipe --> */}
//                   </div>
//                   {/* <!-- end of recipes list --> */}
//                 </section>
//                 {/* <!-- end of recipes container --> */}
//               </main>
//               {/* <!-- end of main --> */}
//               <script src="./js/app.js"></script>
//             </body>
//           </html>
//         );
// }