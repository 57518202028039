import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/main.css';
import SearchBox from "../searchBox";

export default function Recipes() {
  return (
    <main className="page">
        <SearchBox/>
    </main>
  );
}