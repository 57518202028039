import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

function SearchBox() {
  const [recipes, setRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [images, setImages] = useState({});
//  const [isLoading, setIsLoading] = useState(true);
//  const [numDisplayed, setNumDisplayed] = useState(9);

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchData() {
      try {
//        setIsLoading(true);
        const response = await fetch(`/record/`, { signal: abortController.signal });
        const data = await response.json();
        setRecipes(data);

        // Fetch the images for each recipe
        const newImages = {};
        for (const recipe of data) {
          const imageResponse = await fetch(`/recipe_images/${recipe._id}`, { signal: abortController.signal });
          const imageDataUrl = await imageResponse.text();
          newImages[recipe._id] = imageDataUrl;
        }
        setImages(newImages);
//        setIsLoading(false);
      } catch (err) {
        if (err.name === 'AbortError') {
          // fetch was aborted
        } else {
          console.error(err);
        }
      }
    }
    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);


  const [selectedTags, setSelectedTags] = useState(new Set());

  function handleTagChange(tag, isChecked) {
      setSelectedTags((prevSelectedTags) => {
        const newSelectedTags = new Set(prevSelectedTags);
        if (isChecked) {
          newSelectedTags.add(tag);
        } else {
          newSelectedTags.delete(tag);
        }
        return newSelectedTags;
      });
    }

  const filteredRecipes = recipes.filter(recipe => {
    // Split the search term by comma and trim any whitespace
    const searchTerms = searchTerm.split(',').map(term => term.trim());
    // Check if all search terms are included in the recipe's ingredients
    const includesSearchTerm = recipe.Ingredients && searchTerms.every(term => recipe.Ingredients.toLowerCase().includes(term.toLowerCase()));
    // Check if all selected tags are included in the recipe's tags
    const includesSelectedTags = recipe.Tag && [...selectedTags].every(tag => recipe.Tag.includes(tag));
    // Return true if both conditions are met
    return includesSearchTerm && includesSelectedTags;
  });

  const location = useLocation();

  useEffect(() => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the "tags" query parameter
    const tags = queryParams.get('tags');
    // If the "tags" query parameter is defined, update the selectedTags state
    if (tags) {
      setSelectedTags(new Set(tags.split(',')));
    }
  }, [location]);

//  if (isLoading) {
//    return (
//      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
//    );
//  }
//  const displayedRecipes = filteredRecipes.slice(0, numDisplayed);

  return (
    <section className="recipes-container">
      <div className="recipes-filters">
        <h3>Filter:</h3>
        <input type="text" placeholder="Search by ingredients..." onChange={e => setSearchTerm(e.target.value)}/>
        <section className="tags-section">
          <h3>Tags:</h3>
          <div>
            <input
              type="checkbox"
              id="Breakfast"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Breakfast')}
            />
            <label htmlFor="Breakfast">Breakfast</label>
          </div>
          <div>
              <input
              type="checkbox"
              id="Lunch"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Lunch')}
              />
              <label htmlFor="Lunch">Lunch</label>
          </div>
          <div>
              <input
              type="checkbox"
              id="Dinner"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Dinner')}
              />
              <label htmlFor="Dinner">Dinner</label>
          </div>
          <div>
              <input
              type="checkbox"
              id="Dessert"
              onChange={(e) => handleTagChange(e.target.id, e.target.checked)}
              checked={selectedTags.has('Dessert')}
              />
              <label htmlFor="Dessert">Dessert</label>
          </div>
        </section>
      </div>
      <div className="recipes-list">
        {filteredRecipes.map(recipe => (
	 <LazyLoad key={recipe._id} height={100} offset={[-50, 50]} placeholder={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: '#89af77' }}>Loading...</div>}>
         <div className="recipes-in-list" key={recipe._id} >
          <Link to={'/' + recipe.URL_Name}><h4>{recipe.RecipeName}</h4></Link>
          {/* <Link to={'/' + recipe.RecipeName.replace(/\s+/g, '_').toLowerCase()} ><img src={images[recipe._id]} alt={recipe.RecipeName} className="img recipe-img"/></Link> */}
          <Link to={'/' + recipe.URL_Name} ><img src={images[recipe._id]} alt={recipe.RecipeName} className="img recipe-img"/></Link>
          <p>Prep Time: {recipe.PrepTime} | Cook Time: {recipe.CookTime}</p>
         </div>
	 </LazyLoad>
        ))}
      {/*{filteredRecipes.length > numDisplayed && (
      <button className='btn' onClick={() => setNumDisplayed(numDisplayed + 9)}>
	Load more recipes...
      </button>
      )} */}
      </div>
    </section>
  );
}

export default SearchBox;
