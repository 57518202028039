import React from "react";
//import { useState, useEffect } from 'react';
import {Link} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/main.css';

function MainPage() {
//  const [recipes, setRecipes] = useState([]);
//  const [searchTerm] = useState('');
//  const [images, setImages] = useState({});
//  const [isLoading, setIsLoading] = useState(true);

//  useEffect(() => {
//    const abortController = new AbortController();
//    async function fetchData() {
//      try {
//        setIsLoading(true);
//        const response = await fetch(`/record/`, { signal: abortController.signal });
//        const data = await response.json();
//        setRecipes(data);

        // Fetch the images for each recipe
//        const newImages = {};
//        for (const recipe of data) {
//          const imageResponse = await fetch(`/recipe_images/${recipe._id}`, { signal: abortController.signal });
//          const imageDataUrl = await imageResponse.text();
//          newImages[recipe._id] = imageDataUrl;
//        }
//        setImages(newImages);
//        setIsLoading(false);
//      } catch (err) {
//        if (err.name === 'AbortError') {
          // fetch was aborted
//        } else {
//          console.error(err);
//        }
//      }
//    }
//    fetchData();
//
//    return () => {
//      abortController.abort();
//    };
//  }, []);
//

//  const filteredRecipes = recipes.filter(recipe =>
//    recipe.Ingredients.toLowerCase().includes(searchTerm.toLowerCase())
//  );

  // function importAll(r) {
  //   let images = {};
  //   r.keys().map((item) => ( images[item.replace('./', '')] = r(item) ));
  //   return images;
  // }

  // const images = importAll(require.context('/public', false, /\.(png|jpe?g|svg)$/));

//  function getRandomRecipes(recipes, count) {
    // Create a copy of the input array to avoid modifying the original data
//    const recipesCopy = [...recipes];
    // Shuffle the copy of the input array
//    for (let i = recipesCopy.length - 1; i > 0; i--) {
//      const j = Math.floor(Math.random() * (i + 1));
//      [recipesCopy[i], recipesCopy[j]] = [recipesCopy[j], recipesCopy[i]];
//    }
    // Return the first `count` elements of the shuffled array
//    return recipesCopy.slice(0, count);
//  }

//  const randomRecipes = getRandomRecipes(filteredRecipes, 3);

//  if (isLoading) {
//    return (
//      <main className="page">
//          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
//      </main>
//    );
//  }

  return (
          <div>
              {/* <!-- end of nav --> */}
              {/* <!-- main --> */}
              <main className="page">
                {/* <!-- header --> */}
                <header className="hero">
                  <div className="hero-container">
                    <div className="hero-text">
                      {/* <h1_white></h1_white>
                      <h4></h4> */}
                    </div>
                  </div>
                </header>
                {/* <!-- end of header --> */}
                <div className="main-filler">
                  <h5>Welcome to goodfoodwithmoo! Please enjoy our recipes and follow us on <a href="https://www.instagram.com/goodfoodwithmoo/?hl=en">Instagram!</a></h5>
                </div>
                {/* <!-- recipes container --> */}
                <section className="home-recipes-container">
                  {/* <!-- tag container --> */}
                  <div className="home-tags-container">
                    <h4>Jump to...</h4>
                    <div className="home-tags-list">
                      <Link to="/recipes?tags=Breakfast">Breakfast</Link>
                      <Link to="/recipes?tags=Lunch">Lunch</Link>
                      <Link to="/recipes?tags=Dinner">Dinner</Link>
                      <Link to="/recipes?tags=Dessert">Dessert</Link>
                    </div>
                  </div>
                </section>
              </main>
              <script src="./js/app.js"></script>
          </div>
        );
} export default MainPage;
