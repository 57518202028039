import React from "react";
import { useRef, useEffect, useCallback } from 'react';
// We import bootstrap to make our application look better.
import '../styles/main.css';
//import "bootstrap/dist/css/bootstrap.min.css";

//import Link functionality
import {Link} from "react-router-dom";

function Navbar() {
  const navBtnRef = useRef(null);
  const linksRef = useRef(null);
  const navLinksRef = useRef([]);

  const setNavLinkRef = useCallback((element) => {
    if (element) {
      navLinksRef.current.push(element);
    }
  }, []);

  useEffect(() => {
    const navBtnDOM = navBtnRef.current;
    const linksDOM = linksRef.current;
    const navLinksDOM = navLinksRef.current;

    navBtnDOM.addEventListener('click', () => {
      linksDOM.classList.toggle('show-links');
    });

    navLinksDOM.forEach((link) => {
      link.addEventListener('click', () => {
        linksDOM.classList.remove('show-links');
      });
    });
  }, []);


  return (
      // <html lang="en">
      <div>
            <meta charSet="UTF-8" />
            <meta className="viewport" content="width=device-width, initial-scale=1.0" />
            <title>goodfoodwithmoo</title>
            <link rel="shortcut icon" href="./styles/assets/goodfoodwithmoo_favicon.ico" type="image/x-icon" />
            <link rel="stylesheet" href="./css/normalize.css" />
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap" rel="stylesheet"/>
            <link rel="stylesheet" href="./css/main.css" />
          <div>
            <nav className="navbar">
              <div className="nav-center">
                <div className="nav-header">
                  <ul>
                    <Link to="/" className="nav-logo"> <h1> goodfoodwithmoo</h1></Link>
                  </ul>
                    <button type="button" className="btn nav-btn" ref={navBtnRef}>
                      <i className="fas fa-align-justify"></i>
                    </button>
                </div>
                <div className="nav-links" ref={linksRef}>
                    <Link to="/" className="nav-link" style={{fontSize: '22px'}} ref={setNavLinkRef}> Home </Link>
                    <Link to="/about" className="nav-link" style={{fontSize: '22px'}} ref={setNavLinkRef}> About </Link>
                    <Link to="/recipes" className="nav-link" style={{fontSize: '22px'}} ref={setNavLinkRef}> Recipes </Link>
                    {/* <Link to="/testpage" className="nav-link" style={{fontSize: '20px'}}> Test </Link> */}
                  <div className="nav-link contact-link">
                    <Link to="/contact" className="btn">contact</Link>
                  </div>
                </div>
                {/* <Link to="/about"> About </Link> */}
              </div> 
            </nav>
          </div>
      {/* </html> */}
      </div>
  );
} export default Navbar;

