import React from "react";

// We import bootstrap to make our application look better.
import "../styles/main.css";

export default function Footer() {
    return (
        <div>
            <footer className="page-footer">
                <p>
                    &copy; <span id="date"> {(new Date().getFullYear())} </span>
                    <span className="footer-logo"> <a href="https://www.instagram.com/goodfoodwithmoo/?hl=en">goodfoodwithmoo</a> </span>
                    | Website by <a href="https://www.linkedin.com/in/matthew-eberz/"> Matthew Eberz</a>
                </p>
            </footer>
        </div>
    );
}
