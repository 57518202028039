import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function RecipePage() {
  const [recipe, setRecipe] = useState(null);
  // const { RecipeName } = useParams();
  // const formattedRecipeName = RecipeName.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  const { URL_Name } = useParams();
  const formattedRecipeName = URL_Name
  const [images, setImage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await fetch(`/record/${formattedRecipeName}`, { signal: abortController.signal });
        const data = await response.json();
        setRecipe(data);

        // Fetch the images for each recipe
        const newImage = {};
        const imageResponse = await fetch(`/recipe_images/${data._id}`, { signal: abortController.signal });
        const imageDataUrl = await imageResponse.text();
        newImage[data._id] = imageDataUrl;
        setImage(imageDataUrl);
        setIsLoading(false);
      } catch (err) {
        if (err.name === 'AbortError') {
          // fetch was aborted
        } else {
          console.error(err);
        }
      }
    }
    fetchData();

    return () => {
      abortController.abort();
    };
  }, [formattedRecipeName]);


  // if (!recipe) return <div>Loading...</div>;
  if (isLoading) {
    return (
      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
  }

  // if (recipe) {
    const instructions = recipe.Instructions.split('|');
    // rest of the code
    const instructionList = instructions.map((instruction, index) => (
      <div className="single-instruction">
        <header>
        <p>step {index + 1}</p>
        <div></div>
        </header>
        <p>{instruction}</p>
      </div>
    ));
    const ingredients = recipe.Ingredients.split('|');
    const ingredientsList = ingredients.map((ingredient) => (
      <div className="single-ingredient">
        <input type='checkbox' id={ingredient} />
        <label htmlFor={ingredient}>{ingredient}</label>
      </div>  
    ));
    
    return (
      <div className="page">
      <section className="recipe-hero">
          <img src={images} className="img recipe-hero-img" alt={recipe.RecipeName}/>
          <article>
          <h2>{recipe.RecipeName}</h2>
          <p>{recipe.RecipeDescription}</p>
          <div className="recipe-icons">
          <article>
              <i className="fas fa-clock"></i>
              <h5>prep time</h5>
              <p>{recipe.PrepTime}</p>
          </article>
          <article>
              <i className="fas fa-clock"></i>
              <h5>cook time</h5>
              <p>{recipe.CookTime}</p>
          </article>
          <article>
              <i className="fas fa-user-friends"></i>
              <h5>servings</h5>
              <p>{recipe.Servings}</p>
          </article>
          </div>
          </article>
      </section>
      <section className="recipe-content">
          <article className='first-column'>
          <h4>instructions</h4>
          {instructionList}
          </article>
          <article className="second-column">
          <div>
              <h4>ingredients</h4>
              {ingredientsList}
          </div>
          </article>
      </section>
      </div>
    );
  // } else {
  //   return <div>Loading...</div>;
  // }
}
export default RecipePage;
