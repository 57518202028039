import React from "react";

// We import all the components we need in our app
//import footer from "./components/footer";

import "bootstrap/dist/css/bootstrap.min.css";
import './styles/main.css';
//import { useState } from 'react';
// import ReactDOM from 'react-dom'; 

import Navbar from "./pages/navbar";
import MainPage from "./pages/index2";
import Footer from "./pages/footer";
import About from "./pages/about";
import Recipes from "./components/recipes-page";

// import Contact from "./pages/contact";
import Contact from "./pages/contact_axios";
import RecipePage from './components/recipePage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<MainPage/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/:URL_Name" element={<RecipePage/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/recipes" element={<Recipes/>} />
         </Routes>
        <Footer />
      </div>
    </Router>
  );
}

