import React, { useState } from 'react';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = { name, email, message };

    try {
      const response = await fetch('/api/forma', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        // Handle successful form submission
        alert('Message sent successfully!')
      } else {
        // Handle error
        alert('An error occurred. Please try again.')
      }
    } catch (error) {
      // Handle error
      alert('An error occurred. Please try again.')
    }
  };

  return (
    <div className='page'>
    <div className="container">
        <h1>Contact Us</h1>
        <div className="border"></div>
        <form method="POST" action="/" onSubmit={handleSubmit}>
            <div className="singleItem-name">
                <label htmlFor="name">Name</label>
                <input type="text" placeholder="Name" value={name} onChange={(event) => setName(event.target.value)}/>
            </div>
            <div className="singleItem-email">
                <label htmlFor="email">Email</label>
                <input type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)}/>
            </div>
            <div className="singleItem-message">
                <label htmlFor="message">Message</label>
                <textarea placeholder="Message" value={message} onChange={(event) => setMessage(event.target.value)}/>

            </div>
            {/* <div className={this.state.sent ? 'msg msgAppear' : 'msg'}>Message has been sent</div> */}
            <button className="btn" type="submit">Submit</button>
        </form>
    </div>
    </div>  
  );
}

export default ContactForm;
